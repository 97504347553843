export const environment = {
    production: false,
    baseUrl: "https://data.dev.bookeeapp.com/",
    baseApiUrlAutomation: "https://automations.dev.bookeeapp.com/",
    baseUrlCampaign: "https://campaign.develop.onbookee.com/",
    pusherKey: "661d4be379f71c3f0ef5",
    newCrmUrl: "https://crm.app.dev.bookeeapp.com",
    xCrmUrl: "https://xcrm.dev.bookeeapp.com",
    logRocket: true,
    intercomAppId: "lzcn0xd6",
    chargebee: "bookeeapp-test",
    chargebee_api_key: "test_FpAnpoA1ueGS9rFK1jkqA0jzu5Doh3XH",
    adyenMode: "test",
    env_name: 'dev',
    posthog_api_key: 'phc_cvIqiKhhcEecQsLG75c9Tz4om1B2U6rxcoz0Eb2rZn1',
    ai_base_url: 'https://anton.stage.bookeeapp.com',
    challenge:'https://5e517f4d6897.ap-south-1.sdk.awswaf.com/5e517f4d6897/3a6c126da886/challenge.js',
    google_secret: "AIzaSyDkJ_WnY7vm1jTHUYI8YWREXSvvjNkfcoM&libraries=places"
};